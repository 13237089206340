<template>
  <section
    class="n-section-tertiary !tw-px-0"
    :style="`background-color: var(--c-${vars.backgroundColor});color: ${vars.textColor ? `var(--c-${vars.textColor})` : 'var(--c-text-primary)'}`"
  >
    <div class="tw-mb-4 tw-flex tw-w-full tw-items-center tw-justify-center tw-px-8 tw-text-center md:tw-px-20">
      <h2>{{ vars.titleText }}</h2>
    </div>
    <div class="roadmap-area tw-w-full">
      <div class="roadmap-wrap tw-flex tw-flex-col tw-justify-between">
        <div
          data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569db1"
          class="v5-slide-left !tw-absolute !tw-bottom-1/2 !tw-left-2 !tw-z-30 tw-translate-y-1/2 tw-bg-white md:!tw-flex"
          @click="() => swiper.slidePrev()"
        >
          <svg width="28" height="12" viewBox="0 0 32 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M31 5.25H31.75V6.75H31V5.25ZM0.469669 6.53033C0.176777 6.23744 0.176777 5.76256 0.469669 5.46967L5.24264 0.696699C5.53553 0.403806 6.01041 0.403806 6.3033 0.696699C6.59619 0.989593 6.59619 1.46447 6.3033 1.75736L2.06066 6L6.3033 10.2426C6.59619 10.5355 6.59619 11.0104 6.3033 11.3033C6.01041 11.5962 5.53553 11.5962 5.24264 11.3033L0.469669 6.53033ZM31 6.75H1V5.25H31V6.75Z"
              fill="var(--c-text-primary)"
            />
          </svg>
        </div>
        <div
          data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569db1"
          class="v5-slide-right !tw-absolute !tw-bottom-1/2 !tw-right-2 !tw-z-30 tw-translate-y-1/2 tw-bg-white md:!tw-flex"
          @click="() => swiper.slideNext()"
        >
          <svg width="28" height="12" viewBox="0 0 32 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M1 5.25H0.25V6.75H1V5.25ZM31.5303 6.53033C31.8232 6.23744 31.8232 5.76256 31.5303 5.46967L26.7574 0.696699C26.4645 0.403806 25.9896 0.403806 25.6967 0.696699C25.4038 0.989593 25.4038 1.46447 25.6967 1.75736L29.9393 6L25.6967 10.2426C25.4038 10.5355 25.4038 11.0104 25.6967 11.3033C25.9896 11.5962 26.4645 11.5962 26.7574 11.3033L31.5303 6.53033ZM1 6.75H31V5.25H1V6.75Z"
              fill="var(--c-text-primary)"
            />
          </svg>
        </div>
        <Swiper
          :modules="[SwiperPagination]"
          @swiper="onSwiper"
          :pagination="false"
          :space-between="30"
          :looped-slides="items.length"
          :loop="false"
          :breakpoints="{
            320: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
            1536: {
              slidesPerView: 4,
              spaceBetween: 40,
            },
          }"
          class="roadmap-active tw-w-full"
        >
          <SwiperSlide v-for="(item, index) in items" :key="index">
            <div class="roadmap-item">
              <div class="roadmap-content tw-text-start">
                <span class="dot"></span>
                <h6 class="title tw-mb-6 tw-text-[var(--c-tertiary)]">{{ item.title }}</h6>
                <p class="tw-line-clamp-4">
                  {{ item.description }}
                </p>
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  </section>
</template>
<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'Theme6Timeline',

  mixins: [ComponentMixin],

  data() {
    return {
      swiper: null,
      canPaginateBack: false,
      canPaginateNext: true,
    };
  },

  computed: {
    items() {
      return this.groupedVariables.items;
    },
  },

  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },

    onSlideChange() {
      if (!this.swiper) return;
      this.canPaginateBack = true;
      this.canPaginateNext = true;
    },
  },
});
</script>

<style scoped>
.no-hover {
  display: none !important;
}

@media (max-width: 767px) {
  .no-hover {
    display: flex !important;
  }
}

.roadmap-wrap {
  position: relative;
}
.roadmap-wrap::before {
  content: '';
  position: absolute;
  left: 0;
  top: calc(50% - 0px);
  transform: translateY(-50%);
  width: 100%;
  height: 2px;
  border: 1px dashed var(--c-tertiary);
}
.roadmap-item {
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  width: 414px;
  height: 318px;
  flex: 0 0 auto;
  align-self: flex-end;
}
.roadmap-content {
  position: relative;
  border-radius: 16px;
  padding: 30px 35px;
  margin-bottom: 37px;
}
.roadmap-content .dot {
  width: 14px;
  height: 14px;
  position: absolute;
  left: 30px;
  transform: translateX(-50%);
  bottom: 10px;
  background: var(--c-primary);
  border-radius: 50%;
}
.roadmap-content p {
  margin-bottom: 12px;
  min-height: 100px;
}
.roadmap-area {
  overflow: hidden;
}
.roadmap-active ::v-deep(.swiper-wrapper) {
  min-height: 525px;
}
.roadmap-style-two {
  background: var(--c-primary);
}

.roadmap-active ::v-deep(.swiper-slide:nth-child(odd)) {
  align-self: flex-end;
}
.roadmap-active ::v-deep(.swiper-slide:nth-child(odd) .roadmap-item) {
  flex-direction: column;
}
.roadmap-active ::v-deep(.swiper-slide:nth-child(odd) .roadmap-item .roadmap-content .dot) {
  bottom: auto;
  top: 10px;
}
.roadmap-active ::v-deep(.swiper-slide:nth-child(odd) .roadmap-item .roadmap-content) {
  margin-bottom: 0;
  margin-top: 40px;
}
.roadmap-active ::v-deep(.swiper-slide .roadmap-item .title) {
  margin-top: 15px;
}

@media screen and (max-width: 767px) {
  .roadmap-active ::v-deep(.swiper-slide) {
    align-self: flex-end;
  }
  .roadmap-active ::v-deep(.swiper-slide .roadmap-item) {
    flex-direction: column;
  }
  .roadmap-active ::v-deep(.swiper-slide .roadmap-item .roadmap-content .dot) {
    bottom: auto;
    top: 10px;
  }
  .roadmap-active ::v-deep(.swiper-slide .roadmap-item .roadmap-content) {
    margin-bottom: 0;
    margin-top: 40px;
  }
  .roadmap-active ::v-deep(.swiper-slide .roadmap-item .title) {
    margin-top: 40px;
  }
}

@media screen and (max-width: 560px) {
  .roadmap-active ::v-deep(.swiper-slide:nth-child(odd) .roadmap-item .roadmap-content) {
    margin-bottom: 0;
    margin-top: 40px;
  }
}
</style>
